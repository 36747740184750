import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Layout from '../../components/Layout';
import CookieBanner from '../../components/CookieConcent';


function AboutPage() {
    const markdownContent = `
![Syster Siv](/images/siv-95-small.jpg)

# Min syster Siv har lämnat oss den 29:e Januari 2025
### Begravnig 28:e Februari 2025 i Uppsala Gamla Kyrka Kl:11.00

Artikeln uppdateras....
`;
    return (
        <Layout>
            <div className='md:max-w-[800px] sm:max-w-300 py-2'>
                <CookieBanner />
                <ReactMarkdown
                    children={markdownContent}
                    remarkPlugins={[remarkGfm]}
                    components={{
                        h1: ({ children }) => <h1 className="text-3xl font-bold mb-4">{children}</h1>,
                        h2: ({ children }) => <h2 className="text-2xl font-bold mb-4">{children}</h2>,
                        h3: ({ children }) => <h3 className="text-xl font-semibold mb-4">{children}</h3>,
                        p: ({ children }) => <p className="text-slate-700 mb-4 text-xl">{children}</p>,
                        blockquote: ({ children }) => <blockquote className='p-3 border-l-4 border-mocha'>{children}</blockquote>,
                        table: ({ children }) => (
                            <table className="table-auto w-full border-collapse border border-gray-400">
                                {children}
                            </table>
                        ),
                        th: ({ children }) => (
                            <th className="border border-gray-700 px-4 py-2 bg-gray-200 text-left">
                                {children}
                            </th>
                        ),
                        td: ({ children }) => (
                            <td className="border border-gray-700 px-4 py-2">
                                {children}
                            </td>
                        ),
                        img: ({ children }) => (
                            <img src='/images/siv-95-small.jpg' className="rounded" alt="Syster Siv!">
                                {children}
                            </img>
                        ),
                    }}
                />
            </div>
        </Layout>
    );
}
export default AboutPage;
