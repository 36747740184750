import React from 'react';
import Footer from './Footer';
import Header from './Header';

function Layout({ children }) {

  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      {/* Main Content */}
      <main className="flex-grow bg-slate-200 sm:size-max md:max-w-[950px] mx-auto p-4">
        {children}
      </main>

      <Footer />
    </div>
  );
}

export default Layout;
