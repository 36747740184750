import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    const currentYear = new Date().getFullYear(); // Get the current year
    return (
        <footer className="bg-mocha text-white p-4 mt-1">
            <div className="max-w-[1200px] mx-auto text-center">
                <p className='text-sm'>&copy; {currentYear} <Link to="https://ulferik.se">Ulfs Web</Link> All rights reserved.</p>
                <a href="mailto:ulferik+web@pm.me"><p className='text-sm'>info@ulfs web</p></a>
            </div>
        </footer>
    );
}

export default Footer;
