import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Layout from '../../components/Layout';
import CookieBanner from '../../components/CookieConcent';

export default function CascaisPage() {
    const markdownContent = `
# Cascais är en pittoresk kuststad belägen strax väster om Lissabon, Portugal. 

![Badstrand i Cascais](/images/bad-icascais.jpg)

> Känd för sina vackra stränder, livliga hamn och charmiga gamla stan, är Cascais en populär destination för både turister och lokalbefolkning. Staden har en unik blandning av traditionell portugisisk kultur och modern livsstil, vilket gör den till en perfekt plats att utforska.

> En av stadens mest framträdande drag är dess fantastiska kustområde, där besökare kan njuta av soliga dagar på stränder som Praia da Rainha och Praia do Guincho. Den vackra promenaden längs kusten erbjuder en fantastisk utsikt över Atlanten och är idealisk för promenader eller cykelturer.

> Cascais är också hem för flera historiska byggnader och monument, inklusive det imponerande kungliga palatset, Palácio da Cidadela, och den charmiga kyrkan Nossa Senhora da Assunção. Den livliga marina är en perfekt plats för att avnjuta färsk fisk och skaldjur på någon av de många restaurangerna med havsutsikt.

> Staden har en avslappnad atmosfär och är känd för sina konst- och kulturarrangemang, inklusive konstutställningar och musikfestivaler. Med sin närhet till både natur och stadsliv erbjuder Cascais en perfekt kombination av avkoppling och aktivitet, vilket gör den till en idealisk plats för en semester eller en weekendresa.

I detta paradis bodde vi i fem år från 2016. Vi trivdes gott i Cascais, milda vintrar och heta somrar och kallt Atlantvatten att bada i.
`;
    return (
        <Layout>
            <div className="max-w-[800px] py-12">
                <CookieBanner />
                <ReactMarkdown
                    children={markdownContent}
                    remarkPlugins={[remarkGfm]}
                    components={{
                        h1: ({ children }) => <h1 className="text-3xl font-bold mb-4">{children}</h1>,
                        h2: ({ children }) => <h2 className="text-2xl font-bold mb-4">{children}</h2>,
                        h3: ({ children }) => <h3 className="text-xl font-semibold mb-4">{children}</h3>,
                        p: ({ children }) => <p className="text-slate-700 mb-4">{children}</p>,
                        blockquote: ({ children }) => <blockquote className='p-3'>{children}</blockquote>,
                        table: ({ children }) => (
                            <table className="table-auto w-full border-collapse border border-gray-400">
                                {children}
                            </table>
                        ),
                        th: ({ children }) => (
                            <th className="border border-gray-700 px-4 py-2 bg-gray-200 text-left">
                                {children}
                            </th>
                        ),
                        td: ({ children }) => (
                            <td className="border border-gray-700 px-4 py-2">
                                {children}
                            </td>
                        ),
                        img: ({ children }) => (
                            <img src='/images/bad-icascais.jpg' className="rounded" alt="Badstrand">
                                {children}
                            </img>
                        ),
                    }}
                />
            </div>
        </Layout>
    );
}

