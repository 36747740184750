import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Layout from '../../components/Layout';
import CookieBanner from '../../components/CookieConcent';



function VolvoPage() {
    const markdownContent = `
## Volvo Lastvagnar är ett globalt ledande företag inom transportlösningar, känt för sin kvalitet, säkerhet och innovation.

|  Målkurs  | Rek | Dagskurs |
| :-------: |:----:| :----:  |
| 350 &uarr;| Köp  |  290  |


> Volvo Lastvagnars aktie framstår som köpvärd i dagsläget, med förväntningar på en större utdelning i samband med bolagets starka utveckling. Företaget har fortsatt att leverera imponerande resultat, drivet av en växande efterfrågan på hållbara transportlösningar och innovativa fordon.  

>Inför årsrapporten den 29 januari 2025 är förhoppningarna höga, och analytiker spår positiva nyheter som kan stärka aktiens värde ytterligare. Volvo Lastvagnars position som ledande inom elektrifiering och framtidens logistiklösningar gör aktien till ett intressant alternativ för investerare som söker långsiktig avkastning.  

>Med stabilt kassaflöde och tydlig strategi är detta ett gyllene tillfälle att överväga en investering i ett av Sveriges mest framstående industribolag.
`;
    return (
        <Layout>
            <div className='py-12'>
                <CookieBanner />
                <ReactMarkdown
                    children={markdownContent}
                    remarkPlugins={[remarkGfm]}
                    components={{
                        h1: ({ children }) => <h1 className="text-3xl font-bold mb-4">{children}</h1>,
                        h2: ({ children }) => <h2 className="text-2xl font-bold mb-4">{children}</h2>,
                        h3: ({ children }) => <h3 className="text-xl font-semibold mb-4">{children}</h3>,
                        p: ({ children }) => <p className="text-slate-700 mb-4 text-xl">{children}</p>,
                        blockquote: ({ children }) => <blockquote className='p-3'>{children}</blockquote>,
                        table: ({ children }) => (
                            <table className="border">
                                {children}
                            </table>
                        ),
                        th: ({ children }) => (
                            <th className="bg-gray-200 px-4">
                                {children}
                            </th>
                        ),
                        td: ({ children }) => (
                            <td className="px-4 p-3 border border-l">
                                {children}
                            </td>
                        ),
                        img: ({ children }) => (
                            <img src='/images/Volvolast.jpg' className="rounded" height="150px" width="160px" alt="En marknad i Cannes!">
                                {children}
                            </img>
                        ),
                    }}
                />
            </div>
        </Layout>
    );
}
export default VolvoPage;
