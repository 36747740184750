// src/Router.js
import React, { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import StartPage from '../pages/StartPage';
import HomePage from '../pages/blog/HomePage';
import AboutPage from '../pages/blog/AboutPage';
import VardiutlandetPage from '../pages/blog/Vard-i-utlandet';
import CascaisPage from '../pages/blog/CascaisPage';
import VaccinPage from '../pages/blog/VaccinPage';
import CannesPage from '../pages/blog/CannesPage';
import CookiePage from '../pages/blog/CookiePage';
import MandelieuPage from '../pages/blog/MandelieuPage';
import DraguignanPage from '../pages/blog/DraguignanPage';
import AktierPage from '../pages/aktier/AktierPage';
import VolvoPage from '../pages/aktier/VolvoPage';
/* import SkanskaPage from '../pages/aktier/SkanskaPage'; */
import BalderPage from '../pages/aktier/BalderPage';
import RatePage from '../pages/aktier/RatePage';
import BildPage from '../pages/BildPage';
import SivPage from '../pages/blog/SivPage';
function AppRouter() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<StartPage />} />
                <Route path="/aktier" element={<AktierPage />} />
                <Route path="/blog" element={<HomePage />} />
                <Route path="/cook" element={<CookiePage />} />
                <Route path='/bilder' element={<BildPage />} />
                {/* Blog */}
                <Route path="/about" element={<AboutPage />} />
                <Route path="/vard" element={<VardiutlandetPage />} />
                <Route path="/casc" element={<CascaisPage />} />
                <Route path="/vacc" element={<VaccinPage />} />
                <Route path="/mark" element={<CannesPage />} />
                <Route path="/mandelieu" element={<MandelieuPage />} />
                <Route path="/draguignan" element={<DraguignanPage />} />
                <Route path="/siv" element={<SivPage />} />`    `
                {/* Aktier */}
                <Route path="/volvo" element={<VolvoPage />} />
                {/* <Route path="/skanska" element={<SkanskaPage />} /> */}
                <Route path="/balder" element={<BalderPage />} />
                <Route path="/rate" element={<RatePage />} />
            </Routes>
        </Router>
    );
}

export default AppRouter;