import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Layout from '../components/Layout';
import CookieBanner from '../components/CookieConcent';


function StartPage() {
    const markdownContent = `   
![Forfattaren](/images/myself-working.png)

# Välkommen till min blogsida

 Jag är glad att du besöker min webbplats. Här hittar du en samling artiklar, råd och insikter om olika ämnen som ligger nära mitt hjärta. Jag strävar efter att skapa innehåll som är både informativt och engagerande, och jag hoppas att du hittar något som inspirerar eller intresserar dig.

 ###    Tack för ditt besök!

P.S. Jag har byggt denna sida med React och Tailwind CSS för att skapa en responsiv och modern webbsida. Jag har använt mig av ett API för att hämta innehållet från min blog, vilket gör att du alltid får den senaste informationen. Dessutom har jag använt mig av en cookie-banner för att informera dig om att dessa sidor använder cookies. Detta är för att förbättra din upplevelse och ge dig den bästa möjliga tjänsten.
`;
    return (
        <Layout>
            <div className='card py-12'>
                <CookieBanner />
                <ReactMarkdown
                    children={markdownContent}
                    remarkPlugins={[remarkGfm]}
                    components={{
                        h1: ({ children }) => <h1 className="text-3xl font-bold mb-4">{children}</h1>,
                        h2: ({ children }) => <h2 className="text-2xl font-bold mb-4">{children}</h2>,
                        h3: ({ children }) => <h3 className="text-xl font-semibold mb-4">{children}</h3>,
                        p: ({ children }) => <p className="text-slate-700 mb-4 text-xl">{children}</p>,
                        blockquote: ({ children }) => <blockquote className='p-3 border-l-4 border-mocha'>{children}</blockquote>,
                        table: ({ children }) => (
                            <table className="table-auto w-full border-collapse border border-gray-400">
                                {children}
                            </table>
                        ),
                        th: ({ children }) => (
                            <th className="border border-gray-700 px-4 py-2 bg-gray-200 text-left">
                                {children}
                            </th>
                        ),
                        td: ({ children }) => (
                            <td className="border border-gray-700 px-4 py-2">
                                {children}
                            </td>
                        ),
                        img: ({ children }) => (
                            <img src='/images/myself-working.png' className="rounded" alt="En lite justerad bild av mig!">
                                {children}
                            </img>
                        ),
                    }}
                />
            </div>
        </Layout>
    );
}
export default StartPage;
