import React from 'react';
import data from '../../api/aktierdata.json';
import Layout from '../../components/Layout';
import CookieConcent from '../../components/CookieConcent';


const AktierPage = () => {

    return (
        <Layout>
            <div>
                <CookieConcent />
                {/* Welcome Section */}
                <section className="py-16 text-center text-slate-600">
                    <h2 className="md:text-4xl sm:text-base font-bold mb-4">Välkommen till min aktiesida.</h2>
                    <p className="text-slate-800">
                        Analys av aktier och finansrelaterat.
                    </p>

                </section>
                {/* Grid Section */}
                <section className="py-2">
                    <div className="container mx-auto px-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
                        {data.map(item => (
                            <div className='card card:hover'
                                key={item.id}
                            >
                                <img src={item.bild} className="rounded" alt={item.alt} />
                                <h3 className="card-title">{item.title}</h3>
                                <p className="card-content">{item.description}</p>
                                <br />
                                <a href={item.linc} className="hover:underline"><h3 className='card-title'>Läs mer...</h3></a>
                            </div>
                        ))}
                    </div>
                </section>
            </div>

        </Layout>
    );
};

export default AktierPage;
