import React from 'react';
import AppRouter from './components/AppRouter';
import { useEffect } from 'react';
import { initializeAnalytics, trackPageView } from './components/analytics';
import './App.css';

function App() {
  useEffect(() => {
    if (document.cookie.includes("statistics=true")) {
      initializeAnalytics();
      trackPageView(window.location.pathname);
    }
  }, []);
  return (
    <div className="App">
      <AppRouter />
    </div>
  );
}

export default App;