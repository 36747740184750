import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Layout from '../../components/Layout';
import CookieBanner from '../../components/CookieConcent';


function AboutPage() {
    const markdownContent = `
# Om mig

![Forfattaren](/images/myself-working.png)

Jag heter Ulf, och tillsammans med min hustru Viveca har jag haft den otroliga glädjen att leva i två av världens mest charmerande länder: Frankrike och Portugal. Vår resa genom dessa fantastiska platser har formad både vårt liv och våra perspektiv på kultur, mat och liv i stort.

### Frankrike: Tretton år av Äventyr och Upptäckt

De tretton åren vi tillbringade i Frankrike var fyllda med djupgående upplevelser och oförglömliga minnen. Från att vandra genom de gamla gatorna i Paris och beundra konsten på Louvren, till att njuta av den rena skönheten i *le Midi* och smaka på lokala specialiteter som baguetter och ostar. Vi lärde oss att uppskatta det franska sättet att leva, med en balans mellan arbete och njutning, och vi blev förälskade i den franska filosofin om att njuta av de små tingen i livet.


### Portugal: Fem År av Sol, Hav och Traditioner


Våra fem år i Portugal var en annan värld av skönhet och vänlighet. Med sina pittoreska kustlinjer, historiska städer som Lissabon och Porto, och en rik kultur fylld med musik, dans och gastronomiska delikatesser som Pastéis de Nata, blev Portugal en andra hemmahävd för oss. Här lärde vi oss att uppskatta den portugisiska gästverksamheten och deras kärlek till livet, vidare förstärkt av den vackra naturen och den milda klimatet.
`;
    return (
        <Layout>
            <div className='card py-12'>
                <CookieBanner />
                <ReactMarkdown
                    children={markdownContent}
                    remarkPlugins={[remarkGfm]}
                    components={{
                        h1: ({ children }) => <h1 className="text-3xl font-bold mb-4">{children}</h1>,
                        h2: ({ children }) => <h2 className="text-2xl font-bold mb-4">{children}</h2>,
                        h3: ({ children }) => <h3 className="card-title">{children}</h3>,
                        p: ({ children }) => <p className="card-content">{children}</p>,
                        blockquote: ({ children }) => <blockquote className='p-3 border-l-4 border-mocha'>{children}</blockquote>,
                        table: ({ children }) => (
                            <table className="table-auto w-full border-collapse border border-gray-400">
                                {children}
                            </table>
                        ),
                        th: ({ children }) => (
                            <th className="border border-gray-700 px-4 py-2 bg-gray-200 text-left">
                                {children}
                            </th>
                        ),
                        td: ({ children }) => (
                            <td className="border border-gray-700 px-4 py-2">
                                {children}
                            </td>
                        ),
                        img: ({ children }) => (
                            <img src='/images/myself-working.png' className="rounded pb-3" alt="En lite justerad bild av mig!">
                                {children}
                            </img>
                        ),
                    }}
                />
            </div>
        </Layout>
    );
}
export default AboutPage;
