import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Layout from '../../components/Layout';
import CookieBanner from '../../components/CookieConcent';


function MandelieuPage() {
    const markdownContent = `
    
![Mandelieu CannesMarina](/images/CannesMarina.jpg)

# Drömmen om livet i södern: Våra två år i Mandelieu

För många svenskar känns drömmen om att flytta till södra Europa avlägsen – något man fantiserar om men kanske inte vågar ta steget till. Men jag vill dela med mig av våra två år i Mandelieu, strax utanför Cannes, för att visa att det är fullt möjligt att skapa ett liv i solen.

Vi bodde i en lägenhet på åttonde våningen i området Cannes Marina, med en utsikt som kunde ta andan ur vem som helst. Havet bredde ut sig framför oss, och i fjärran såg vi Cannes. Sommarkvällarna bjöd på magiska fyrverkerier – halvtimmeslånga ljusshower som vi kunde njuta av från vår egen balkong. Det kändes som en dröm som blivit verklighet.

## Ett nytt kapitel av drömmen

De två åren i Mandelieu var som att leva i en film – med havsutsikt, magiska kvällar och vardagar som kändes lite lyxigare än vanligt. Men vår resa i södra Frankrike slutade inte där. Efter Mandelieu tog vi nästa stora steg och köpte ett hus i Draguignan. Men mer om det äventyret i ett senare inlägg...

Om du själv drömmer om att flytta söderut, är det här bara ett exempel på hur livet kan se ut när man vågar ta steget!
`;

    return (
        <Layout>
            <div className="py-12">
                <CookieBanner />
                <ReactMarkdown
                    children={markdownContent}
                    remarkPlugins={[remarkGfm]}
                    components={{
                        h1: ({ children }) => <h1 className="text-3xl font-bold mb-4">{children}</h1>,
                        h2: ({ children }) => <h2 className="text-2xl font-bold mb-4">{children}</h2>,
                        h3: ({ children }) => <h3 className="text-xl font-semibold mb-4">{children}</h3>,
                        p: ({ children }) => <p className="text-slate-700 mb-4 text-xl">{children}</p>,
                        blockquote: ({ children }) => <blockquote className='p-3'>{children}</blockquote>,
                        table: ({ children }) => (
                            <table className="table-auto w-full border-collapse border border-gray-400">
                                {children}
                            </table>
                        ),
                        th: ({ children }) => (
                            <th className="border border-gray-700 px-4 py-2 bg-gray-200 text-left">
                                {children}
                            </th>
                        ),
                        td: ({ children }) => (
                            <td className="border border-gray-700 px-4 py-2">
                                {children}
                            </td>
                        ),
                        img: ({ children }) => (
                            <img src='/images/CannesMarina.jpg' className="rounded" height="500px" width="600px" alt="En marknad i Cannes!">
                                {children}
                            </img>
                        ),
                    }}
                />
            </div>
        </Layout>
    );
}
export default MandelieuPage;