import React from 'react';
import Layout from '../components/Layout';

function BildPage() {
    return (
        <Layout>
            <div>
                <h1 className='text-2xl mb-4'>Bjästabron och centrum sett från Förnätra 1945</h1>
                <img src='/images/Bjasta_1945.jpg' className="rounded" alt="En lite justerad bild av mig!" />
                <ul className="text-slate-600 pt-4">
                    <li>
                        Nordlanders skor och kiosk med biograf till vänster.
                    </li>
                    <li>
                        Sellings Bageri till höger.
                    </li>
                    <li>
                        SågOlles hus med järnhandeln i bottenvåningen bakom bageriet.
                    </li>

                </ul>
            </div>
        </Layout >
    );
};

export default BildPage;
