import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Layout from '../../components/Layout';


function BalderPage() {
    const markdownContent = `
# Balder

![Balder](https://www.tradingview.com/x/05yXeZ4O/)

`;
    return (
        <Layout>
            <div>
                <ReactMarkdown
                    children={markdownContent}
                    remarkPlugins={[remarkGfm]}
                    components={{
                        h1: ({ children }) => <h1 className="text-4xl font-bold mb-4">{children}</h1>,
                        h2: ({ children }) => <h2 className="text-3xl font-bold mb-4">{children}</h2>,
                        h3: ({ children }) => <h3 className="text-2xl font-semibold mb-4">{children}</h3>,
                        p: ({ children }) => <p className="mb-4 text-xl">{children}</p>,
                        blockquote: ({ children }) => <blockquote className='p-3'>{children}</blockquote>,
                        table: ({ children }) => (
                            <table className="table-auto w-full border-collapse border border-gray-400">
                                {children}
                            </table>
                        ),
                        th: ({ children }) => (
                            <th className="border border-gray-700 px-4 py-2 bg-gray-200 text-left">
                                {children}
                            </th>
                        ),
                        td: ({ children }) => (
                            <td className="border border-gray-700 px-4 py-2">
                                {children}
                            </td>
                        ),
                        img: ({ children }) => (
                            <img src='https://www.tradingview.com/x/05yXeZ4O/' className="rounded" height="800px" width="900px" alt="En marknad i Cannes!">
                                {children}
                            </img>
                        ),
                    }}
                />
            </div>
        </Layout>
    );
}
export default BalderPage;
