import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Layout from '../../components/Layout';
import CookieBanner from '../../components/CookieConcent';

function VaccinPage() {
    const markdownContent = `

![Skoterska vaccinerar!](/images/vaccinering-sm.jpg)

# Influensavaccination är effektivt

Ett sätt att minska risken för att bli allvarligt sjuk av influensa, både för dig själv och för andra i din omgivning. Här är några aspekter att tänka på:

### Varför influensavaccin är viktigt
1. **Skydd mot allvarlig sjukdom**: Vaccinet minskar risken för allvarliga komplikationer, särskilt hos äldre, gravida, och personer med kroniska sjukdomar.
2. **Herdimmunitet**: När fler vaccineras minskar spridningen av influensan i samhället, vilket skyddar de som inte kan vaccinera sig av medicinska skäl.
3. **Mildare sjukdom**: Även om du får influensan efter vaccination blir symptomen ofta mildare och sjukdomsperioden kortare.

### Är ovaccinerade farligare?
**Direkt farliga är de inte**, men ovaccinerade personer:
- **Kan sprida viruset snabbare**: De löper högre risk att bli smittade och kan därmed föra viruset vidare till fler.
- **Bidrar till en större spridning i samhället**: Detta ökar risken för att sårbara grupper ska bli exponerade.

Vaccinerade personer är mindre benägna att bli smittade och har också mindre virusmängd om de skulle bli sjuka, vilket gör dem mindre smittsamma.

### Rekommendationer
- **Vaccinera dig**: Särskilt om du tillhör en riskgrupp eller har nära kontakt med personer i riskgrupper.
- **Praktisera god hygien**: Oavsett vaccinationsstatus hjälper handtvätt och att undvika att röra ansiktet till att minska spridningen av virus.
- **Stanna hemma vid sjukdom**: Det viktigaste för att skydda andra.

Om du är osäker på om du bör vaccinera dig, prata med en läkare. De kan ge råd baserat på din hälsa och livsstil.

Teorin du nämner kopplas ofta till **hygienhypotesen**, som föreslår att ett för "rent" och steriliserat liv kan leda till att immunsystemet inte blir tillräckligt stimulerat. Detta tros öka risken för autoimmuna sjukdomar och allergier, eftersom immunsystemet i brist på "riktiga fiender" börjar attackera kroppens egna vävnader eller ofarliga ämnen.

## Det finns en teori om att immunsystemet inte behöver jobba och då kan det uppstå autoimmuna sjukdomar. Finns det några bevis för detta?

### Vad säger forskningen?
Det finns vissa studier som stödjer denna teori, men sambandet är komplext och inte helt bevisat. Här är några nyckelpunkter:

#### 1. **Exponering för mikroorganismer tidigt i livet**
   - Forskning visar att barn som växer upp på bondgårdar eller har syskon ofta löper mindre risk att utveckla allergier och autoimmuna sjukdomar som astma eller diabetes typ 1. Detta kan bero på större exponering för bakterier och virus, vilket "tränar" immunsystemet.
   - Brist på sådana exponeringar kan göra immunsystemet överreaktivt mot ofarliga ämnen (t.ex. pollen eller kroppens egna celler).

#### 2. **Vaccinationer och autoimmunitet**
   - Vacciner är designade för att stimulera immunsystemet på ett kontrollerat sätt. Det finns inga starka bevis för att vaccinationer leder till en högre risk för autoimmuna sjukdomar.
   - Tvärtom har vissa vacciner visats minska risken för autoimmunitet, eftersom de kan modulera immunsystemet och minska inflammatoriska reaktioner.

#### 3. **Moderna levnadsförhållanden**
   - Minskad exponering för infektioner och naturliga miljöer kan vara en faktor bakom ökningen av autoimmuna sjukdomar, men detta är bara en del av ett större pussel.
   - Andra faktorer som genetik, stress, kost, och miljögifter bidrar också till autoimmuna sjukdomar.

### Slutsats
Det finns viss evidens för att bristande exponering för mikroorganismer tidigt i livet kan påverka risken för autoimmunitet, men detta gäller främst miljöfaktorer snarare än vaccinationer. Vacciner bidrar till att skydda mot infektioner och har inga starka kopplingar till ökad autoimmunitet.
`;

    return (
        <Layout>
            <div className="md:max-w-[800px] sm:max-w-300 py-2">
                <CookieBanner />
                <ReactMarkdown
                    children={markdownContent}
                    remarkPlugins={[remarkGfm]}
                    components={{
                        h1: ({ children }) => <h1 className="text-3xl font-bold mb-4">{children}</h1>,
                        h2: ({ children }) => <h2 className="text-2xl font-bold mb-4">{children}</h2>,
                        h3: ({ children }) => <h3 className="text-xl font-semibold mb-4">{children}</h3>,
                        p: ({ children }) => <p className="text-slate-700 mb-4 ml-5">{children}</p>,
                        blockquote: ({ children }) => <blockquote className='p-3'>{children}</blockquote>,
                        table: ({ children }) => (
                            <table className="table-auto w-full border-collapse border border-gray-400">
                                {children}
                            </table>
                        ),
                        th: ({ children }) => (
                            <th className="border border-gray-700 px-4 py-2 bg-gray-200 text-left">
                                {children}
                            </th>
                        ),
                        td: ({ children }) => (
                            <td className="border border-gray-700 px-4 py-2">
                                {children}
                            </td>
                        ),
                        img: ({ children }) => (
                            <img src="/images/vaccinering-sm.jpg" className="rounded" alt="French doktor">
                                {children}
                            </img>
                        ),
                    }}
                />
            </div>
        </Layout>
    );
}

export default VaccinPage;