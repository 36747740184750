import React from 'react';
import data from '../../api/blogdata.json';
import Layout from '../../components/Layout';
import CookieConcent from '../../components/CookieConcent';


const HomePage = () => {

    return (
        <Layout>
            <div>
                <CookieConcent />
                {/* Welcome Section */}
                <section className="py-12 md:text-center sm:text-sm text-slate-600 p-3">
                    <h2 className="md:text-4xl sm:text-xl font-bold mb-4">Välkommen till min blog.</h2>
                    <p className="text-slate-600">
                        Här är platsen där jag delar med mig av mina tankar, erfarenheter och passioner.
                    </p>

                </section>
                {/* Grid Section */}
                <section className="py-2">
                    <div className="container mx-auto px-4 grid sm:grid-cols-1 md:grid-cols-3 gap-6">
                        {data.map(item => (
                            <div className='card' key={item.id}>
                                <img src={item.bild} className="rounded" alt={item.alt} />
                                <h3 className="card-title">{item.title}</h3>
                                <p className="card-content">{item.description}</p>
                                <br />
                                <a href={item.linc} className="hover:underline"><h3 className='font-bold text-slate-500'>Läs mer...</h3></a>
                            </div>
                        ))}
                    </div>
                </section>
            </div>

        </Layout>
    );
};

export default HomePage;
